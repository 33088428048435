import {
  isEmpty,
  isPhoneNumberLengthValid,
  isPhoneNumberFormat,
  IsSelectInputSelected
} from "../validator";

// isMain | Boolean -> main phone number is cannot be empty
// val | String -> the input value
// errorSetter | function -> function that set the error message content
// valueStter | function -> function that set input value
export const validatePhoneNumber = (isMain, val, errorSetter, valueSetter) => {
  errorSetter("");
  if (isMain && isEmpty(val)) {
    errorSetter("Nomor Handphone Wajib Diisi");
    return false;
  }

  //only check if value is not empty so the error message
  //will not be triggered when the input is not mandatory
  if (!isEmpty(val)) {
    if (isNaN(val)) {
      errorSetter("Nomor Handphone Harus Berupa Angka");
      valueSetter("");
    } else if (!isPhoneNumberLengthValid(val)) {
      errorSetter("Nomor Handphone Harus Terdiri dari 10 - 13 Angka");
      return false;
    } else if (!isPhoneNumberFormat(val)) {
      errorSetter("Format Nomor Handphone Tidak Sesuai");
      return false;
    }
  }
  return true;
};

export const validateInputSelected = (val, errorSetter, errorMessage) => {
  errorSetter(null);
  if (!IsSelectInputSelected(val)) {
    errorSetter(errorMessage);
    return false;
  }
  return true;
};

export const validateInputEmpty = (val, errorSetter, errorMessage) => {
  errorSetter(null);
  if (isEmpty(val)) {
    errorSetter(errorMessage);
    return false;
  }
  return true;
};

export const getBase64Data = (base64: string) => {
  const blockBase64 = base64.split(";");
  let dataImg;
  if (blockBase64.length > 0) dataImg = blockBase64[1].split(",")[1];

  return dataImg;
};

export const base64toBlob = (
  b64Data: any,
  contentType = "",
  sliceSize = 512
) => {
  b64Data = getBase64Data(b64Data);
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const validatePhoneLength = (e) => {
  if (e.target.value.length > e.target.maxLength)
    e.target.value = e.target.value.slice(0, e.target.maxLength);
};
